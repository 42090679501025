import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';

declare var $:any;
declare var iziToast:any;

@Component({
  selector: 'app-bd-survey-report',
  templateUrl: './bd-survey-report.component.html',
  styleUrls: ['./bd-survey-report.component.css']
})
export class BdSurveyReportComponent implements OnInit {

  constructor( private serverService:ServerService) { }
admin_id;
call_history_list;
accesstoken;
recordNotFound = false;
pageLimit = 10;
paginationData:any ={"info":"hide"};
offset_count = 0;
res;
camp_list;
  ngOnInit(): void {

    this.admin_id=localStorage.getItem('admin_id');
    this.accesstoken= localStorage.getItem('access_token')
    // this.getList();
    this.callHistoryList({});
    this.campaign_list();
  }
  campaign_list(){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"campaign", "moduleType":"campaign", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"camp_list","user_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
       
        this.camp_list = response.result.data;
        console.log(this.camp_list);
      } else {
        this.recordNotFound = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
genReport(){
let from=$('#gen_from').val();
let to = $('#gen_to').val();
let camp_id = $('#camp_id').val();
if(from == null || from == ''){
  iziToast.warning({
  message: "Please Select From Date",
  position: 'topRight'
});
return false;
}
if(to == null || to == ''){
  iziToast.warning({
  message: "Please Select To Date",
  position: 'topRight'
});
return false;
}

  let api_req:any='{"access_token":"'+this.accesstoken+'","operation":"predective_dialer_contact","moduleType":"predective_dialer_contact","api_type":"web","element_data":{"action":"bs_report","from_dt" : "'+from+'", "to_dt" : "'+to+'","camp_id" : "'+camp_id+'","admin_id":"'+this.admin_id+'"}}'
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.status==true){
      this.res = response;
       var arrStr = encodeURIComponent(JSON.stringify(this.res));
       // document.location.href = 'https://omni.mconnectapps.com/api/storage/contact/download.php?res='+arrStr;
       var url = 'https://omni.mconnectapps.com/api/storage/call/bd_report.php';
       var form = $('<form action="' + url + '" method="post">' +
       '<input type="text" name="res" value="' + arrStr + '" />' +
       '</form>');
       $('body').append(form);
       form.submit();
    } 
  }, 
  (error)=>{
      console.log(error);
  });
}
callHistoryList(data){

  var list_data= this.listDataInfo(data);
  let api_req:any = new Object();
  let history_req:any = new Object();
  history_req.action="bs_report_list";
  history_req.search_text=list_data.search_text;
// history_req.order_by_name=list_data.order_by_name;
 history_req.order_by_type=list_data.order_by_type;
 history_req.limit=list_data.limit;
 history_req.offset=list_data.offset;
  history_req.admin_id=localStorage.getItem('admin_id');
  api_req.operation="predective_dialer_contact";
  api_req.moduleType="predective_dialer_contact";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = history_req;
        this.serverService.sendServer(api_req).subscribe((response:any) => {
        
          if(response.result.status==true){
         
            
            this.call_history_list=response.result.data.list_data;
            this.offset_count = list_data.offset;
            this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
            this.recordNotFound = this.call_history_list.length == 0 ? true : false;
          }
           
        }, 
        (error)=>{
            console.log(error);
        });

}
listDataInfo(list_data){

  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
  list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}

searchDatalist(data){
  var list_data= this.listDataInfo(data);
  let from=$('#gen_from').val();
  let to = $('#gen_to').val();
  let camp_id = $('#camp_id').val();
  if(from == null || from == ''){
    iziToast.warning({
    message: "Please Select From Date",
    position: 'topRight'
  });
  return false;
  }
  if(to == null || to == ''){
    iziToast.warning({
    message: "Please Select To Date",
    position: 'topRight'
  });
  return false;
  }
  Swal.fire({
    title: 'Please Wait',
    allowEscapeKey: false,
    allowOutsideClick: false,
    background: '#19191a',
    showConfirmButton: false,
    onOpen: ()=>{
        Swal.showLoading();
    }
  });

  let api_req:any = new Object();
  let history_req:any = new Object();
  history_req.action="bs_report_list";
  history_req.search_text=list_data.search_text;
// history_req.order_by_name=list_data.order_by_name;
 history_req.order_by_type=list_data.order_by_type;
 history_req.limit=list_data.limit;
 history_req.offset=list_data.offset;
 history_req.from_dt=from;
 history_req.to_dt=to;
 history_req.camp_id=camp_id;
  history_req.admin_id=localStorage.getItem('admin_id');
  api_req.operation="predective_dialer_contact";
  api_req.moduleType="predective_dialer_contact";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = history_req;
        this.serverService.sendServer(api_req).subscribe((response:any) => {


        Swal.close();
          if(response.result.status==true){
         
            this.call_history_list=response.result.data.list_data;
            this.offset_count = list_data.offset;
            this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
            this.recordNotFound = this.call_history_list.length == 0 ? true : false;
          }
           
        }, 
        (error)=>{
            console.log(error);
        });

}
}
