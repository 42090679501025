import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';

declare var iziToast:any;
declare var $:any;

@Component({
  selector: 'app-ref-all-teams-user',
  templateUrl: './ref-all-teams-user.component.html',
  styleUrls: ['./ref-all-teams-user.component.css']
})
export class RefAllTeamsUserComponent implements OnInit {

 
  constructor(private serverService:ServerService) { }
  adminlists;
  node_id;
  ngOnInit(): void {
this.getAllTeamsUser();
   // this.getNodeUsers();
  }
  getAllTeamsUser(){
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"list_all_users_ref_team"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        this.adminlists= response.result.data; 
        console.log(this.adminlists);
      } 
      // else {
      //   this.recordNotFound = true;
      // }
    }, 
    (error)=>{
        console.log(error);
    }); 

  }

  addTeamsUser(){

  
    let cus_name=$('#admin_name').val();
    let exp_date=$('#exp_date').val();
    let api_req:any = new Object;
    api_req.operation="agents";
    api_req.moduleType="agents";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data=new Object;
    
    api_req.element_data.action="add_users_ref_team";
    api_req.element_data.company_name=cus_name;
    api_req.element_data.domain=exp_date;  

    // if(agent_req.status == true){  this.dep_status = 1 } else { this.dep_status  = 0 }
        let access_token: any=localStorage.getItem('access_token');
      
        // let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_report","report_name":"'+agent_req.department_name+'","report_url":"'+agent_req.report_path+'"}}';
      
            this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.result.data == 1) {
                    $('#add_license').modal('hide');
                    iziToast.success({
                        message: "User reference added successfully",
                        position: 'topRight'
                    });
                    this.getAllTeamsUser();
            }
            else if (response.result.data == 2) {
              iziToast.warning({
                  message: "Duplicates found",
                  position: 'topRight'
              });
          }
            else{
                
                    iziToast.error({
                        message: "Please try again",
                        position: 'topRight'
                    });
                
            }
      
        },
        (error) => {
             iziToast.error({
                message: "Sorry, some server issue occur. Please contact admin",
                position: 'topRight'
            });
            console.log(error);
        });
      
      
      
      }
 editLicdata(name,id){
        let access_token: any=localStorage.getItem('access_token');
        let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_teams_users_ref","company_name":"'+name+'"}}';
        this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.status==true){
  $('#editNode_license').modal('show');
  let user_data= response.result.data;
  $('#Upd_admin_name').val(user_data.company_name);
  $('#Upd_exp_date').val(user_data.domain);
this.node_id=user_data.id;

}
},(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
          });
      });
 }
 updateNodeLicense(id){
  let cus_name= $('#Upd_admin_name').val();
  let exp_date= $('#Upd_exp_date').val();
  let access_token: any=localStorage.getItem('access_token');

  let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"update_users_ref_team","id":"'+id+'","company_name":"'+cus_name+'","domain":"'+exp_date+'"}}';
this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.result.data==1){
  this.getAllTeamsUser();
  iziToast.success({
    message:"User reference updated succesfully",
    position:"topRight"
  });
  $('#editNode_license').modal('hide');

}else{
  iziToast.warning({
    message:"some error occured.please try again",
    position:"topRight"
  });
}        },(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
          });
      });
 }

 
  actCamp(id){
        var stat = '0'; 
         if($('#status_node_'+id).prop('checked')){ stat = '1';}
        let access_token: any=localStorage.getItem('access_token');
        
                let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"toggle_status_license_key","id":"'+id+'","status":"'+stat+'"}}';
                this.serverService.sendServer(api_req).subscribe((response:any) => {
                  if(response.result.data == 1){
                      this.getAllTeamsUser();
                      iziToast.success({
                        message:"License Status changed",
                        position:"topRight"
                      });  
       }else{
         iziToast.error({
           message:"Nothing has been updated.Please try again",
           position:"topRight"
         });
       }

        }, 
        (error)=>{
            console.log(error);
        });
      }  
      

      deleteLicense(id){
                let access_token: any=localStorage.getItem('access_token');
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
              let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"dele_users_ref_team","id":"'+id+'"}}';
              this.serverService.sendServer(api_req).subscribe((response:any) => {
                if(response.status==true){
                 this.getAllTeamsUser();
                 iziToast.success({
                  message:"Customer data deleted successfully",
                  position:"topRight"
                });
                } 
        
              }, 
              (error)=>{
                  console.log(error);
              });
            }
          })
               
       }

}
