import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-netwrix-report',
  templateUrl: './netwrix-report.component.html',
  styleUrls: ['./netwrix-report.component.css']
})
export class NetwrixReportComponent implements OnInit {
 
  call_history_list;
  recordNotFound = false;
  pageLimit = 20;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  getRep: FormGroup;
  auxcodes;
  admin_id;
  user_id;
  usersL;
  res;
  constructor(private serverService: ServerService) { }

   ngOnInit() {
     this.callHistoryList({});
     this.getRep = new FormGroup({
     'rep_format' :new FormControl(null),      
     'aget_ids':new FormControl(null),
     'report_name' :new FormControl(null),
     'from_date' :new FormControl(null),
     'to_date' :new FormControl(null),
     });
     this.user_id = localStorage.getItem('userId');
     this.admin_id=localStorage.getItem('admin_id');
     this.getUsers();

   }




   getUsers(){
    let api_req:any = new Object();
    let agents_req:any = new Object();
    agents_req.action="get_queue";
    agents_req.admin_id=localStorage.getItem('admin_id');
    api_req.operation="netwrix";
    api_req.moduleType="netwrix";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = agents_req;
    console.log(api_req);
        this.serverService.sendServer(api_req).subscribe((response:any) => {
          console.log(response.result.data);
          if(response.result.status==true){
            this.usersL = response.result.data;
          }
        }, 
        (error)=>{
            console.log(error);
        });
  }

 listDataInfo(list_data){

   list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
   list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
   list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
   list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
   list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
   return list_data;
 }



 callHistoryList(data){

  var list_data= this.listDataInfo(data);
  let api_req:any = new Object();
  let history_req:any = new Object();
  history_req.action="report_list";
  history_req.search_text=list_data.search_text;
  history_req.order_by_name=list_data.order_by_name;
  history_req.order_by_type=list_data.order_by_type;
  history_req.limit=list_data.limit;
  history_req.offset=list_data.offset;
  history_req.user_id=localStorage.getItem('userId');
  api_req.operation="report";
  api_req.moduleType="report";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = history_req;
        this.serverService.sendServer(api_req).subscribe((response:any) => {
        
          if(response.result.status==1){
         
            this.call_history_list=response.result.data.list_data;
            this.offset_count = list_data.offset;
            this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
            this.recordNotFound = this.call_history_list.length == 0 ? true : false;
          }
           
        }, 
        (error)=>{
            console.log(error);
        });

}







   getReports(){



   //let auxcode_name: any= $('#auxcode_name').val();
   let fromDate: any= $('#from_date').val();
   let to_date: any= $('#to_date').val();
   if(fromDate == null || fromDate == ''){
     iziToast.warning({
     message: "Please Select From Date",
     position: 'topRight'
   });
   return false;
   }
   if(to_date == null || to_date == ''){
     iziToast.warning({
     message: "Please Select To Date",
     position: 'topRight'
   });
   return false;
   }
  //  console.log(this.getRep.value);
   let api_req:any = new Object();
   let agents_req:any = new Object();
   if(this.getRep.value.rep_format == "chart_excel")
   agents_req.action="chart_excel";
   else
   agents_req.action="chart";
   
   var queue = this.getRep.value.aget_ids.join();
   agents_req.admin_id = localStorage.getItem('admin_id');
   agents_req.user_id = localStorage.getItem('userId');
   agents_req.queue = queue;
   agents_req.type = "Chart Report";
   agents_req.from_dt = this.getRep.value.from_date;
   agents_req.to_dt = this.getRep.value.to_date;
   agents_req.report_name = this.getRep.value.report_name;
   api_req.operation="netwrix";
   api_req.moduleType="netwrix";
   api_req.api_type="web";
   api_req.access_token = localStorage.getItem('access_token');
  // agents_req.agent_id = localStorage.getItem('userId');
   api_req.element_data = agents_req;
  //  console.log(api_req);
     this.serverService.sendServer(api_req).subscribe((response:any) => {
     console.log(response);
       if(response.result.data==1){
        this.callHistoryList({});
        iziToast.success({
          message: "Report Created Successfully",
          position: 'topRight'
        });
     } else {
       iziToast.warning({
       message: "No Records Found. Please try again",
       position: 'topRight'
     });
     }
     }, 
   (error)=>{
    iziToast.error({
      message: "Sorry, some Server error occured. Please contact  admin",
      position: 'topRight'
    });
     console.log(error);
   });
   }
 







}
