import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-wallboard',
  templateUrl: './marketplace-wallboard.component.html',
  styleUrls: ['./marketplace-wallboard.component.css']
})
export class MarketplaceWallboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
