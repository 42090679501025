import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avaya-report',
  templateUrl: './avaya-report.component.html',
  styleUrls: ['./avaya-report.component.css']
})
export class AvayaReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
