import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { ServerService } from '../services/server.service';
import { DatePipe } from '@angular/common';

declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2' 
@Component({ 
  selector: 'app-wp-pay',
  templateUrl: './wp-pay.component.html',
  styleUrls: ['./wp-pay.component.css']
})
export class WpPayComponent implements OnInit {
  addAcc: FormGroup;
  editAcc:FormGroup;
  admin_id;
  qusn_list;
  acc_list;
  chat_queue;
  acc_id;
  recordNotFound;
  url_date;
  customer_num;
  omni_num;
  chat_name;
  constructor(private serverService: ServerService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.admin_id = localStorage.getItem('admin_id');
    this.addAcc = new FormGroup({
      'acc_name' : new FormControl(null,Validators.required),
      'agent_cc' : new FormControl(null,Validators.required),
      'agent_phone_number' : new FormControl(null,Validators.required),
      'agent_acc_no' : new FormControl(null,Validators.required),
      'agent_pin' : new FormControl(null,Validators.required),
      'agent_provider' : new FormControl(null,Validators.required),
      'agent_balance' : new FormControl(null,Validators.required)
     
     });
     this.editAcc = new FormGroup({
      'acc_name' : new FormControl(null,Validators.required),
      'agent_cc' : new FormControl(null,Validators.required),
      'agent_phone_number' : new FormControl(null,Validators.required),
      'agent_acc_no' : new FormControl(null,Validators.required),
      'agent_pin' : new FormControl(null,Validators.required),
      'agent_provider' : new FormControl(null,Validators.required),
      'agent_balance' : new FormControl(null,Validators.required)
    });
    
     this.get_acc();
     
    //  var today = $filter('date')(new Date(),'yyyy-MM-dd HH:mm:ss Z');
     var date = new Date();
     this.url_date=this.datePipe.transform(date,"dd-MM-yyyy");
     console.log(this.url_date)
    //  var currentDate =new Date().getMonth().toString();
    //  console.log(currentDate);
  }
  get_acc(){

    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"wp_pay", "moduleType":"wp_pay", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"view_bank"}}';
  
                  this.serverService.sendServer(api_req).subscribe((response:any) => {
                  
                      if(response.result.status == true){
                  
                        this.acc_list=response.result.data;
                        console.log(response);
                        console.log( this.acc_list);
                        
                      }else {
                        this.recordNotFound = true;
                      }
                  }, 
                  (error)=>{
                      console.log(error);
                  });
  
  }

      addAccData(){
      
        if(this.addAcc.value.agent_acc_no.toString().length != 10){
          iziToast.warning({
            message: "Account Number should be 10 digits",
            position: 'topRight'
        });
           return false;
        }
        if(this.addAcc.value.agent_pin.toString().length != 4){
            iziToast.warning({
                message: "Account Pin should be 4 digits",
                position: 'topRight'
            });
           return false;

        }
        

            let api_req:any = new Object();
            let add_agent_req:any = new Object();
          //add_agent_req.admin_id=localStorage.getItem('admin_id');
            add_agent_req.name=this.addAcc.value.acc_name;
            add_agent_req.country_code=this.addAcc.value.agent_cc;
            add_agent_req.phone_no=this.addAcc.value.agent_phone_number;
            add_agent_req.account_no=this.addAcc.value.agent_acc_no;
            add_agent_req.pin=this.addAcc.value.agent_pin;
            add_agent_req.mobile_money_provider=this.addAcc.value.agent_provider;
            add_agent_req.balance=this.addAcc.value.agent_balance;
       
            add_agent_req.action='add_bank_acc';
            api_req.operation="wp_pay";
            api_req.moduleType="wp_pay";
            api_req.api_type="web";
            api_req.element_data = add_agent_req;
                this.serverService.sendServer(api_req).subscribe((response: any) => {
                    if (response.result.data == true) {
                        $('#add_accform').modal('hide');
                        this.addAcc.reset();
                        iziToast.success({
                            message: " Customer Account added successfully",
                            position: 'topRight'
                        });
                        this.get_acc();
                       
                    } 
                else{
                    
                        iziToast.warning({
                            message: "Customer not added. Please try again",
                            position: 'topRight'
                        });
                    
                }
        
            },
            (error) => {
                 iziToast.error({
                    message: "Sorry, some server issue occur. Please contact admin",
                    position: 'topRight'
                });
                console.log(error);
            });
        
        
          }
        
          editAccForm(id){
            console.log(id);
            // /this.editAcc.reset();
              let api_req:any = new Object();
              let get_agent_req:any = new Object();
              get_agent_req.id=id;
              get_agent_req.action='edit_bank';
              api_req.operation="wp_pay";
              api_req.moduleType="wp_pay";
              api_req.api_type="web";
            //  api_req.access_token=localStorage.getItem('access_token');
              api_req.element_data = get_agent_req;
                  this.serverService.sendServer(api_req).subscribe((response: any) => {
                  if (response.result.status== true) {
                         var agent_data = response.result.data[0];
                         $('#edit_accform').modal('show');

                           this.editAcc.setValue({
                              'acc_name' : agent_data.name,
                              'agent_cc' : agent_data.country_code,

                              'agent_phone_number' : agent_data.phone_no,
                              'agent_acc_no' : agent_data.account_no,
                              'agent_pin' : agent_data.pin,
                              'agent_provider' : agent_data.mobile_money_provider,
                              'agent_balance' : agent_data.balance
                           
             
                          });
                          this.acc_id = agent_data.id;
                         
                      }
                  else{
                      
                          iziToast.warning({
                              message: "Account data could not retrive. Please try again",
                              position: 'topRight'
                          });
                      
                  }
          
              },
              (error) => {
                  iziToast.error({
                      message: "Sorry, some server issue occur. Please contact admin",
                      position: 'topRight'
                  });
                  console.log(error);
              });
          
          }


          editAccData(id){
console.log(id);
           
            let agent_req:any = this.editAcc.value;
           let api_req:any = '{"operation":"wp_pay", "moduleType": "wp_pay", "api_type": "web","element_data":{"action":"update_bank","id": "'+id+'","name":"'+agent_req.acc_name+'","country_code":"'+agent_req.agent_cc+'","phone_no":"'+agent_req.agent_phone_number+'","account_no":"'+agent_req.agent_acc_no+'","pin":"'+agent_req.agent_pin+'","mobile_money_provider":"'+agent_req.agent_provider+'","balance":"'+agent_req.agent_balance+'"}}';
                    
            //api_req.access_token=localStorage.getItem('access_token');
           
                this.serverService.sendServer(api_req).subscribe((response: any) => {
                  console.log(response);
                if (response.result.status == 1) {
                        $('#edit_accform').modal('hide');
                        this.editAcc.reset();
                        iziToast.success({
                            message: "Agent updated successfully",
                            position: 'topRight'
                        });
                      this.get_acc();
                    }
                else{
                    
                        iziToast.warning({
                            message: "Agent data not updated. Please try again",
                            position: 'topRight'
                        });
                    
                }
        
            },
            (error) => {
                 iziToast.error({
                    message: "Sorry, some server issue occur. Please contact admin",
                    position: 'topRight'
                });
                console.log(error);
            });
        
        
          }     

      addQuestion(){
        $('#add_accform').modal('show');
      }


      viewchat(cc,phone,name){
console.log(phone);
var ph = "+"+cc+ +phone;
console.log(ph);
this.chat_name=name;
        let api_req:any = '{"operation":"wp_pay", "moduleType":"wp_pay", "api_type": "web", "element_data":{"action":"get_chats","phone_no":"'+ph+'"}}';
  
        this.serverService.sendServer(api_req).subscribe((response:any) => {
        
            if(response.result.status == true){
              this.chat_queue=response.result.data;
              this.omni_num=response.result.data[0].from_no;
              this.customer_num=response.result.data[0].to_no;
              console.log(this.customer_num);
      
              
            }else {
              this.recordNotFound = true;
            }
        }, 
        (error)=>{
            console.log(error);
        });
        $('#pop_accform').modal('show');
      }
}
