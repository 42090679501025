import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';

declare var iziToast:any;
declare var $:any;
@Component({
  selector: 'app-sms-services',
  templateUrl: './sms-services.component.html',
  styleUrls: ['./sms-services.component.css']
})
export class SmsServicesComponent implements OnInit {

  constructor(private serverService:ServerService) { }
  adminlists;
  node_id;
  comp_name;
  cost_sms;
  no_of_sms;
  
  ngOnInit(): void {
this.getAllLicense();
   // this.getNodeUsers();
  }
  getAllLicense(){
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"sms_server", "moduleType":"sms_server", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"view_sms_server"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        this.adminlists= response.result.data; 
        console.log(this.adminlists);
      } 
      // else {
      //   this.recordNotFound = true;
      // }
    }, 
    (error)=>{
        console.log(error);
    }); 

  }

  addLicData(){

  
    let cus_name=$('#admin_name').val();
    let exp_date=$('#exp_date').val();
    let api_req:any = new Object;
    api_req.operation="sms_server";
    api_req.moduleType="sms_server";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data=new Object;
    
    api_req.element_data.action="add_sms_server";
    api_req.element_data.server_name=cus_name;
    api_req.element_data.url=exp_date;  

    // if(agent_req.status == true){  this.dep_status = 1 } else { this.dep_status  = 0 }
        let access_token: any=localStorage.getItem('access_token');
      
        // let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_report","report_name":"'+agent_req.department_name+'","report_url":"'+agent_req.report_path+'"}}';
      
            this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.result.data == 1) {
                    $('#add_license').modal('hide');
                    iziToast.success({
                        message: "SMS Service added successfully",
                        position: 'topRight'
                    });
                    this.getAllLicense();

                }
                else if (response.result.data == 0) {
                  iziToast.warning({
                      message: "License not inserted",
                      position: 'topRight'
                  });
              }
            else{
                
                    iziToast.error({
                        message: "Please try again",
                        position: 'topRight'
                    });
                
            }
      
        },
        (error) => {
             iziToast.error({
                message: "Sorry, some server issue occur. Please contact admin",
                position: 'topRight'
            });
            console.log(error);
        });
      
      
      
      }
 editLicdata(id){
        let access_token: any=localStorage.getItem('access_token');
        let api_req:any = '{"operation":"sms_server", "moduleType": "sms_server", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_sms_server","id":"'+id+'"}}';
        this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.status==true){
  $('#editNode_license').modal('show');
  let user_data= response.result.data[0];
  $('#Upd_admin_name').val(user_data.server_name);
  $('#Upd_exp_date').val(user_data.url);
this.node_id=user_data.id;

}
},(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
          });
      });
 }
 displayTagInfo(id){
  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"sms_server", "moduleType": "sms_server", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_cost_details","id":"'+id+'"}}';
  this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.status==true){


this.comp_name= response.result.data;
if(this.comp_name==''|| this.comp_name==null){
   iziToast.info({
     message:"Services info is Empty",
     position:"topRight"
   });
}
else
$('#showpop').modal('show');

// this.comp_name=user_data.company_name;
// this.no_of_sms=user_data.no_of_sms;
// this.cost_sms=user_data.cost;

// let a=$('#company_'+id).val(user_data.company_name);
// alert(this.comp_name);
// $('#no_sms_'+id).val(user_data.no_of_sms);
// $('#cost_'+id).val(user_data.cost);
// this.node_id=user_data.id;

}
},(error)=>{
    console.log(error);
    iziToast.error({
      message:"Server issue occured.",
      position:"topRight"
    });
});

 }
 updateNodeLicense(id){
  let cus_name= $('#Upd_admin_name').val();
  let exp_date= $('#Upd_exp_date').val();
  let access_token: any=localStorage.getItem('access_token');

  let api_req:any = '{"operation":"sms_server", "moduleType": "sms_server", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"update_sms_server","id":"'+id+'","server_name":"'+cus_name+'","url":"'+exp_date+'"}}';
this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.result.data==1){
  this.getAllLicense();
  iziToast.success({
    message:"SMS Service updated succesfully",
    position:"topRight"
  });
  $('#editNode_license').modal('hide');

}else{
  iziToast.warning({
    message:"some error occured.please try again",
    position:"topRight"
  });
}        },(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
          });
      });
 }

 
  actCamp(id){
        var stat = '0'; 
         if($('#status_node_'+id).prop('checked')){ stat = '1';}
        let access_token: any=localStorage.getItem('access_token');
        
                let api_req:any = '{"operation":"sms_server", "moduleType": "sms_server", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"sms_server_status","id":"'+id+'","status":"'+stat+'"}}';
                this.serverService.sendServer(api_req).subscribe((response:any) => {
                  if(response.result.data == 1){
                      this.getAllLicense();
                      iziToast.success({
                        message:"Service Status changed",
                        position:"topRight"
                      });  
       }else{
         iziToast.error({
           message:"Nothing has been updated.Please try again",
           position:"topRight"
         });
       }

        }, 
        (error)=>{
            console.log(error);
        });
      }  
      

      deleteLicense(id){
                let access_token: any=localStorage.getItem('access_token');
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
              let api_req:any = '{"operation":"sms_server", "moduleType":"sms_server", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"delete_sms_server","id":"'+id+'"}}';
              this.serverService.sendServer(api_req).subscribe((response:any) => {
                if(response.status==true){
                 this.getAllLicense();
                 iziToast.success({
                  message:"SMS Service deleted successfully",
                  position:"topRight"
                });
                } 
        
              }, 
              (error)=>{
                  console.log(error);
              });
            }
          })
               
       }

}

