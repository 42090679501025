import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-custom-wallboard-nine',
  templateUrl: './custom-wallboard-nine.component.html',
  styleUrls: ['./custom-wallboard-nine.component.css']
})

export class CustomWallboardNineComponent implements OnInit {
  websocket;
  socketData;
  user_type;
  loginUser;
  admin_id;
  fullviewshow= true;
  has_hard_id;
  admin_permision;
 show_act_wall = false;
datasshow:any=[];
datashowing:any=[];
qnumber;
rowspanval;
Agent_data;
queueData;
rowspan_length=15;
  constructor(private serverService: ServerService, private router:Router) {


   }

  ngOnInit(): void {

  this.user_type = localStorage.getItem('user_type');
  this.loginUser = localStorage.getItem('userId');
  this.admin_id = localStorage.getItem('admin_id');

  this.user_type = localStorage.getItem('user_type');
  this.admin_permision = localStorage.getItem('admin_permision');

 this.has_hard_id = localStorage.getItem('hardware_id');

  if(this.has_hard_id == ""){
      $("#addLicence").modal({"backdrop": "static"});
      this.show_act_wall = true;
  } else {
    this.initSocket();
    // alert('asas')
    // this.getagentsdata();
  }

  }
  fullWindow(){
    if(this.fullviewshow == true){
this.fullviewshow = false;
    }else{
      this.fullviewshow = true;
    }
  var element = document.getElementById("wallboardFullPanel");
  element.classList.toggle("fullSize");
  }

 showdoc(link){   
   //  this.doc_link=link;
   // $("#document_model").modal('show');   
  }
addQueue(){
  $('#addQueue').modal('show');
}
reloadQueue(){
  Swal.fire({
    title: 'Are you sure?',
    text: "This will reset the Wallboard. You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Reset!'
  }).then((result) => {
    if (result.value) {
      var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"reset"}]}]';
  this.websocket.send(socket_message);
    }
  });
}

initSocket(){
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    // if(this.loginUser == '64'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5013/"); 
    // } else if(this.loginUser == '164'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else if(this.loginUser == '201'){
    //   this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    //   // this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else {
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5012/"); 
    // }


    if(this.admin_id == '64'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
     }else if(this.admin_id == '201'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4015"); 
      }else if(this.admin_id == '1230'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4015"); 
      } else {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
    }

    this.websocket.onopen = function(event) { 
      $('#sendonload').click();
      // console.log(this.websocket);
    }

    this.websocket.onmessage = function(event) {
      console.log(event.data);

      var result_message: any = JSON.parse(event.data);

      this.has_hard_id = localStorage.getItem('hardware_id');

      if(result_message[0].aw9_data!= undefined ){

 if(result_message[0].type == 'singledata'){
// for (var i = 0; i < this.datashowing.length; i++) {  
// if(this.datashowing[i].aw9_qno == result_message[0].aw9_data[0].aw9_qno){
// for (var j = 0; j < this.datashowing[i].aw9_qdata.length; j++) {
// if(this.datashowing[i].aw9_qdata[j].agent_no_w9 ==  result_message[0].aw9_data[0].aw9_qdata[0].agent_no_w9){
// this.datashowing[i].aw9_qdata[j] = result_message[0].aw9_data[0].aw9_qdata[0];
//     }
//   }
// }
// }
        $('#single_agent').val(event.data); 
        $('#single_agent').click();   
}
else{
        $('#Table_Data').val(event.data);
        $('#Table_Data').click();
}


      }
      else if(result_message[0].data[0].aw9_agent_name != undefined){
        $('#Agent_Data').val(event.data);
        $('#Agent_Data').click();
      }
       else if(result_message[0].data[0].queue_name!=undefined){
        $('#ques_list').val(event.data); 
        $('#ques_list').click();
      }





     
    } 

    
    this.websocket.onerror = function(event){
      console.log('error');
    }
    this.websocket.onclose = function(event){
      console.log('close');
    }  
  }

removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}



sendOnload(){
    var socket_message  =  '[{"Name":"onload"}]';
    var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"advance_wallboard_9"}]}]' ;
    this.websocket.send(socket_message);
  }

  checkLicenseKey(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.value=='1'){
        this.initSocket();            
      } else {
        iziToast.error({
          message: "Your Licence Key is expired!.. please enter your key or contact admin",
          position: 'topRight'
          });
          $("#addLicence").modal({"backdrop": "static"});
          this.show_act_wall = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  getAgentsData(){
    var socketData =$('#Table_Data').val();
    //console.log(socketData)
  
    // var arrayToString = JSON.stringify(Object.assign({}, socketData)); 
    var mData=JSON.parse(socketData);
    //console.log(mData);
    this.datashowing=mData[0].aw9_data;
    // this.rowspan_length=this.datashowing[0].aw9_qdata.length;
    // alert(this.rowspan_length);
    //console.log(this.datashowing);
  }
  SelectAgentsData(){
    var socketData =$('#Agent_Data').val();
    var mData=JSON.parse(socketData);

    console.log(mData);
    this.Agent_data=mData[0].data;

  }

changeData(){
  let socketData = $('#ques_list').val(); 
  let mData = JSON.parse(socketData);
  this.queueData = mData[0].data;
}

getQueues(){
  var mainQueues = $('.queues_check:checked').map(function(){
    return this.value;
}).get();


if(mainQueues == ''){

  iziToast.warning({
    message: "Please Select Atleast One",
    position: 'topRight'
});
  return false;
}
this.has_hard_id = localStorage.getItem('hardware_id');
 mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
//  alert(mainQueues);
 console.log(mainQueues);
 this.websocket.send(mainQueues);
 $('#addQueue').modal('hide');
}

getQueUsers(){
  var mainAgents = $('.agents_check:checked').map(function(){
    return this.value;
}).get();


if(mainAgents == ''){

  iziToast.warning({
    message: "Please Select Atleast One",
    position: 'topRight'
});
  return false;
}
this.has_hard_id = localStorage.getItem('hardware_id');

mainAgents = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainAgents.join()+']}]';
console.log(mainAgents);
 this.websocket.send(mainAgents);
 $('#addUsers').modal('hide');
}

addUsers(){
  $('#addUsers').modal('show');
}

slectAllQue(){
  if($("#selectAllQ").prop("checked")) {
    $(".queues_check").prop("checked", true);
} else {
    $(".queues_check").prop("checked", false);
} 
}

slectunique(){
  $("#selectAllQ").prop("checked",false)
  }

slectAllagent(){
  if($("#slectAllagent").prop("checked")) {
    $(".agents_check").prop("checked", true);
} else {
    $(".agents_check").prop("checked", false);
} 
}
slectuniqueagent(){
  $("#slectAllagent").prop("checked",false)
  }


single_agent(){
  let socketData = $('#single_agent').val(); 
  let mData = JSON.parse(socketData);

  console.log(mData);
  var single_agent = mData[0].aw9_data[0].aw9_qdata;
  // console.log(single_agent);
  // console.log(single_agent[0].aw9_qdata);

  



  var agent_no_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_agent_no_w9';
  var obanswered_w9= single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_obanswered_w9';
  var obtalktime_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_obtalktime_w9';
  var obavgtalktime_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_obavgtalktime_w9';
  var obmissed_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_obmissed_w9';
  var inboundanswered_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_inboundanswered_w9';
  var inboundtalktime_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_inboundtalktime_w9';
  var tottalktime_w9 = single_agent[0].qno_w9+'_'+single_agent[0].agent_no_w9+'_tottalktime_w9';
  // alert(agent_no_w9);
  // alert(single_agent[0].agent_no_w9);

  $('#'+agent_no_w9).html(single_agent[0].agent_no_w9);
  $('#'+obanswered_w9).html(single_agent[0].obanswered_w9);
  $('#'+obtalktime_w9).html(single_agent[0].obtalktime_w9);
  $('#'+obavgtalktime_w9).html(single_agent[0].obavgtalktime_w9);
  $('#'+obmissed_w9).html(single_agent[0].obmissed_w9);
  $('#'+inboundanswered_w9).html(single_agent[0].inboundanswered_w9);
  $('#'+inboundtalktime_w9).html(single_agent[0].inboundtalktime_w9);
  $('#'+tottalktime_w9).html(single_agent[0].tottalktime_w9);
  





}




}
