import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2'
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Router } from '@angular/router';
declare var $:any;
declare var iziToast:any;

@Component({
  selector: 'app-cardlife',
  templateUrl: './cardlife.component.html',
  styleUrls: ['./cardlife.component.css']
})
export class CardlifeComponent implements OnInit {
  queue_list;
  recordNotFound = false;
  recordNotFound_g = false;
  uadmin_id;
  agents_list;
  userchecked;
  AccountResponseDescription;
  AccountResponseStatus;
  BillingAddress1;
  BillingAddress2;
  BillingAddress3;
  BillingAddress3City;
  BillingAddress4SGCounty;
  BillingAddressCountry;
  BillingAddressPostalCode;
  DateOfBirth;
  Email;
  FullLegalName;
  NRIC;
  Phone;
  SpecialRemarks;ContractProduct;
  CustomerNumber;
  DiagnosticContractProduct;
  SpouseDOB;
  SpouseEmailAddress;
  SpouseMobile;
  SpouseNRIC;
  SpouseName;
  param1;
  admin_id;
  FinanceTabResponseDescription;
  FinanceTabResponseStatus;
  FinanceTabResponseValue;
  customersListed;
  AccountResponseStatus_det;
  AccountResponseDescription_det;
  AccountResponseStatu_det;
  BillingAddress1_det;
  BillingAddress2_det;
  BillingAddress3_det;
  BillingAddress3City_det;
  BillingAddress4SGCounty_det;
  BillingAddressCountry_det;
  BillingAddressPostalCode_det;
  DateOfBirth_det;
  Email_det;
  FullLegalName_det;
  NRIC_det;
  Phone_det;
  SpecialRemarks_det;ContractProduct_det;
  CustomerNumber_det;
  DiagnosticContractProduct_det;
  SpouseDOB_det;
  SpouseEmailAddress_det;
  SpouseMobile_det;
  SpouseNRIC_det;
  SpouseName_det;
  AccountResponseDescription_det_g;
  AccountResponseStatus_det_g;
  BillingAddress1_det_g;
  BillingAddress2_det_g;
  BillingAddress3_det_g;
  BillingAddress3City_det_g;
  BillingAddress4SGCounty_det_g;
  BillingAddressCountry_det_g;
  BillingAddressPostalCode_det_g;
  DateOfBirth_det_g;
  Email_det_g; 
  FullLegalName_det_g;
  NRIC_det_g;
  Phone_det_g;
  SpecialRemarks_det_g;
  ContractProduct_det_g;
  CustomerNumber_det_g;
  DiagnosticContractProduct_det_g;
  SpouseDOB_det_g;
  SpouseEmailAddress_det_g;
  SpouseMobile_det_g;
  SpouseNRIC_det_g;
  SpouseName_det_g;
  FinanceTabResponseValueTable;
  fileTitle = 'Filter';
  labfileTitle = 'Filter';
  labTabResponseValueTable;
  labNoFound;
  labtractResponseValueTable;
  labtrackNoFound;
  lnf = false;
  lnt = false;
  constructor(private serverService: ServerService,private route: ActivatedRoute,private router:Router,private titleService: Title) {
    this.param1 = this.route.snapshot.queryParamMap.get('phone');

  }

  public setTitle(newTitle: string) {
       this.titleService.setTitle(newTitle);
  }
  ngOnInit() {
    this.admin_id = localStorage.getItem('admin_id');
    this.uadmin_id = localStorage.getItem('userId');
    this.get_comp_det(this.param1);
    if(this.admin_id == '478'){
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have no access view that page!',
      });
      this.router.navigate(['/'])
      return false;
    }
  }


  searchForCustomer(){
    var number = $('#searchForCustomer').val();
    this.router.navigate(['/cordlife-contact'], { queryParams: { phone: number } });
    this.get_comp_det(number);
    

  }

  
  get_comp_det(phone){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_comp_det","phone_no":"'+phone+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      
      if(response.AccountResponseStatus == 201){
        this.recordNotFound = true;
        $('#searchForCustomer').val('');
        
      } else {
        this.recordNotFound = false;
        this.AccountResponseDescription = response.AccountResponseDescription;
        this.AccountResponseStatus = response.AccountResponseStatus;
        this.BillingAddress1 = response.AccountResponseValue.ClientMother.BillingAddress1;
        this.BillingAddress2 = response.AccountResponseValue.ClientMother.BillingAddress2;
        this.BillingAddress3 = response.AccountResponseValue.ClientMother.BillingAddress3;
        this.BillingAddress3City = response.AccountResponseValue.ClientMother.BillingAddress3City;
        this.BillingAddress4SGCounty = response.AccountResponseValue.ClientMother.BillingAddress4SGCounty;
        this.BillingAddressCountry = response.AccountResponseValue.ClientMother.BillingAddressCountry;
        this.BillingAddressPostalCode = response.AccountResponseValue.ClientMother.BillingAddressPostalCode;
        this.DateOfBirth = response.AccountResponseValue.ClientMother.DateOfBirth;
        this.Email = response.AccountResponseValue.ClientMother.Email;
        this.FullLegalName = response.AccountResponseValue.ClientMother.FullLegalName;
        this.setTitle(this.FullLegalName);
        this.NRIC = response.AccountResponseValue.ClientMother.NRIC;
        this.Phone = response.AccountResponseValue.ClientMother.Phone;
        this.SpecialRemarks = response.AccountResponseValue.ClientMother.SpecialRemarks;
        this.ContractProduct = response.AccountResponseValue.ContractProduct;
        this.CustomerNumber = response.AccountResponseValue.CustomerNumber;
        this.DiagnosticContractProduct = response.AccountResponseValue.DiagnosticContractProduct;
        this.SpouseDOB = response.AccountResponseValue.Spouse.SpouseDOB;
        this.SpouseEmailAddress = response.AccountResponseValue.Spouse.SpouseEmailAddress;
        this.SpouseMobile = response.AccountResponseValue.Spouse.SpouseMobile;
        this.SpouseNRIC = response.AccountResponseValue.Spouse.SpouseNRIC;
        this.SpouseName = response.AccountResponseValue.Spouse.SpouseName;
        this.get_customers_list(this.CustomerNumber,this.DateOfBirth,this.NRIC,this.Phone);
      }     
    }, 
    (error)=>{
        console.log(error);
    });
  }

  get_bank_det(cus_id){
    let access_token: any=localStorage.getItem('access_token');
  
    // let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_bank_det","cus_id":"'+cus_id+'"}}';
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_bank_det","cus_id":"'+cus_id+'","phone_no":"'+this.param1+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      this.FinanceTabResponseDescription =response.FinanceTabResponseDescription
      this.FinanceTabResponseStatus = response.FinanceTabResponseStatus
      this.FinanceTabResponseValue = response.FinanceTabResponseValue


      this.getContractTableVal(this.FinanceTabResponseValue[0].CustomerNumber, this.FinanceTabResponseValue[0].ContractNumber);
      this.getlabTable(this.FinanceTabResponseValue[0].ContractNumber);

      // if(response.result.status==true){
      //  alert();
      //   this.queue_list = response.result.data;

      // } else {
      //   this.recordNotFound = true;
      // }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  get_customers_list(cus_id,dob,nric,phone_no){
    let access_token: any=localStorage.getItem('access_token');
  
   let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_customer_list","cus_no":"'+cus_id+'","dob":"","nric":"'+nric+'","phone_no":"'+phone_no+'"}}';
    //let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_customer_list","cus_no":"CUS000033112","dob":"","nric":"","phone_no":""}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.AccountResponseStatus==200){
        this.customersListed = response.AccountResponseValue;
        var accGuid = response.AccountResponseValue[0]['AccountGuid'];
        this.get_AccountGuid_list(accGuid);
      } else {
        this.recordNotFound_g = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  get_AccountGuid_list(accountGuid){
    let access_token: any=localStorage.getItem('access_token');
  
    // let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_customer_list","cus_no":"'+cus_id+'","dob":"'+dob+'","nric":"'+nric+'","phone_no":"'+phone_no+'"}}';
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"CustomerInfoByGuid","accountGuid":"'+accountGuid+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.AccountResponseStatus==200){
        this.recordNotFound_g = false;
        this.AccountResponseDescription_det_g = response.AccountResponseDescription;
        this.AccountResponseStatus_det_g = response.AccountResponseStatus;
        this.BillingAddress1_det_g = response.AccountResponseValue.ClientMother.BillingAddress1;
        this.BillingAddress2_det_g = response.AccountResponseValue.ClientMother.BillingAddress2;
        this.BillingAddress3_det_g = response.AccountResponseValue.ClientMother.BillingAddress3;
        this.BillingAddress3City_det_g = response.AccountResponseValue.ClientMother.BillingAddress3City;
        this.BillingAddress4SGCounty_det_g = response.AccountResponseValue.ClientMother.BillingAddress4SGCounty;
        this.BillingAddressCountry_det_g = response.AccountResponseValue.ClientMother.BillingAddressCountry;
        this.BillingAddressPostalCode_det_g = response.AccountResponseValue.ClientMother.BillingAddressPostalCode;
        this.DateOfBirth_det_g = response.AccountResponseValue.ClientMother.DateOfBirth;
        this.Email_det_g = response.AccountResponseValue.ClientMother.Email;
        this.FullLegalName_det_g = response.AccountResponseValue.ClientMother.FullLegalName;
        this.NRIC_det_g = response.AccountResponseValue.ClientMother.NRIC;
        this.Phone_det_g = response.AccountResponseValue.ClientMother.Phone;
        this.SpecialRemarks_det_g = response.AccountResponseValue.ClientMother.SpecialRemarks;
        this.ContractProduct_det_g = response.AccountResponseValue.ContractProduct;
        this.CustomerNumber_det_g = response.AccountResponseValue.CustomerNumber;
        this.DiagnosticContractProduct_det_g = response.AccountResponseValue.DiagnosticContractProduct;
        this.SpouseDOB_det_g = response.AccountResponseValue.Spouse.SpouseDOB;
        this.SpouseEmailAddress_det_g = response.AccountResponseValue.Spouse.SpouseEmailAddress;
        this.SpouseMobile_det_g = response.AccountResponseValue.Spouse.SpouseMobile;
        this.SpouseNRIC_det_g = response.AccountResponseValue.Spouse.SpouseNRIC;
        this.SpouseName_det_g = response.AccountResponseValue.Spouse.SpouseName;

      } else {
        this.recordNotFound_g = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  getContractTableVal (custo_no,contract_no){
    let access_token: any=localStorage.getItem('access_token');
  this.fileTitle = contract_no;
    // let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_customer_list","cus_no":"'+cus_id+'","dob":"'+dob+'","nric":"'+nric+'","phone_no":"'+phone_no+'"}}';
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"SalesDocumentByCustomerContract","customer_no":"'+custo_no+'","contract_no":"'+contract_no+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);

      this.FinanceTabResponseValueTable = response.FinanceTabResponseValue;
    }, 
    (error)=>{
        console.log(error);
    });
  }


  getlabTable (contract_no){
    this.getlabTrack(contract_no);
    let access_token: any=localStorage.getItem('access_token');
    this.labfileTitle = contract_no;
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"lab_tab","contract_no":"'+contract_no+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.LabTabResponseStatus == 201){
            this.labNoFound = response.LabTabResponseDescription;
            this.lnf = true;
      } else {
        this.labTabResponseValueTable = response.LabTabResponseValue;
        this.lnf = false;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }




  getlabTrack(contract_no){
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"cordlife", "moduleType":"cordlife", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"lab_track","contract_no":"'+contract_no+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.LabTabResponseStatus == 201){
        this.labtrackNoFound = response.LabTabResponseDescription;
        this.lnf = true;
      } else {
        this.labtractResponseValueTable = response.LabTabResponseValue;
        this.lnt = false;
      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }




}
