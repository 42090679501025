import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ServerService } from '../services/server.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
declare var $:any;
declare var iziToast:any;
declare var FB: any;
@Component({
  selector: 'app-insta-chat',
  templateUrl: './insta-chat.component.html',
  styleUrls: ['./insta-chat.component.css']
})
export class InstaChatComponent implements OnInit {
  @ViewChild('chat_message', {static: false}) chat_message : ElementRef;
	@ViewChild('chat_detail_id', {static: false}) chat_detail_id : ElementRef; 
  uadmin_id;
  loginUser;
  chat_panel_list;
  search_list;
	chat_panel_details;
	chat_panel_detail_type = "chat_screen";
  chat_detail_key;
  first_name;
  last_name;
  profile_pic;
  page_name;
  page_pic;
  param1;
  callonce;
  temp_list;
  departments;



  insta_chat_list;
  insta_message;
  insta_msg_data;
  single_chat_id;
  single_sender_id;
  profile_photo;
  profile_name;
  format;
  url;
  seen_status = false;
  constructor(private serverService: ServerService,private route: ActivatedRoute) {
    this.serverService.insta_status.subscribe( (val:any) => 
    {
      console.log(val.notification_for)
      if(val.notification_for == 'insta_status'){
        this.chatinstaPanelDetail2(val.unique_id);
      }
        // this.dept_settings();
        // this.hasContactAccess();
     }
     
    );
    this.serverService.active_status.subscribe( (val:any) => 
    {
      console.log(val.update_story)
     
        if(val.update_story==true){
          this.chatinstaPanelDetail2(val.chat_id);
        }
        // this.dept_settings();
        // this.hasContactAccess();
     }
     
    );

  this.param1 = this.route.snapshot.queryParamMap.get('c');
  this.serverService.changeDetectionEmitter.subscribe(
		($event) => {
	
		  let mData = JSON.parse($event);
		  var pagefor = mData.pagefor;
		  var pageid = mData.id;
		  if(pagefor == 'insta'){
			// this.chatPanelDetail(pageid);			
      // this.chatPanelView(pageid);
        // this.chatPanelView2(pageid);
        this.chat_list();
        if(this.single_chat_id==pageid){
        this.chatinstaPanelDetail1(pageid);
        }
        // else
        // this.chatPanelView2("all");

			// setTimeout(()=>{ 
			//   $(".card-body.chat-content").scrollTop($(".card-body.chat-content")[0].scrollHeight);
			//   }, 4000);
		  }
		  
		},
		(err) => {
		}
	  );


   }

  ngOnInit() {
    this.uadmin_id = localStorage.getItem('admin_id');
    this.loginUser = localStorage.getItem('userId');

    if(this.param1){
      this.param1 = atob(this.param1);
      // console.log(this.param1);
      this.chatinstaPanelDetail2(this.param1);
      // this.chatPanelView(this.param1);
      // this.chatPanelView("all");
			// this.chatPanelDetail(this.param1);
		  } else {
			// this.chatPanelView("all");
		  }
// alert("tes");
    // this.chatPanelView("all");
    
      // this.get_temps();
      this.chat_list();
      
   }
   chat_list(){
let api_req:any = '{"operation":"insta_chat", "moduleType":"insta_chat", "api_type": "web", "element_data":{"action":"get_chat_list","admin_id":"'+this.uadmin_id+'"}}';
this.serverService.sendServer(api_req).subscribe((response:any) => {
  console.log(response)
  if(response.result.status==true){
    this.insta_chat_list = response.result.data.chat_list; 
    console.log(this.insta_chat_list)
   
  } 
}, 
(error)=>{
  console.log(error);
});

   }
   chatinstaPanelDetail(chat_id,sender_id){
     this.single_chat_id = chat_id;
     this.single_sender_id = sender_id;
     console.log(this.single_sender_id)
     console.log(this.single_chat_id)
    $('#chat_msg').val('');
    let req:any = new Object();
    let inst_req:any = new Object();
    inst_req.action = "single_chat_data";
    inst_req.chat_id = chat_id;
    req.operation="wpchat";
    req.moduleType="wpchat";
    req.access_token=localStorage.getItem('access_token');
    req.element_data = inst_req;
    this.serverService.sendServer(req).subscribe((response:any)=>{
console.log(response);
if(response.result.status==true){
  this.chatautoScroll();
  this.insta_message = response.result.data;
  this.profile_photo = response.result.data.chat_data.profile_pic;
  this.profile_name = response.result.data.chat_data.first_name;
  this.active_story(this.single_chat_id);
  if(response.result.data.chat_data.seen_status == '1'){
    this.seen_status = true;
  }else{
    this.seen_status = false;
  }
  this.insta_msg_data = response.result.data.chat_list;
  this.chat_panel_detail_type = "chat_detail"; 
  console.log(this.profile_photo)
  console.log(this.profile_name)

  FB.api('/'+this.single_sender_id+'')
}
    })

   }
   active_story(chat_id){
   //$('#chat_msg').val('');
   let req:any = new Object();
   let inst_req:any = new Object();
   inst_req.action = "active_story";
   inst_req.chat_id = chat_id;
   req.operation="wpchat";
   req.moduleType="wpchat";
   req.access_token=localStorage.getItem('access_token');
   req.element_data = inst_req;
   this.serverService.sendServer(req).subscribe((response:any)=>{
console.log(response);
if(response.result.status==true){
  console.log('active story getting successfully');
  var chat_list = response.result.data.chat_list;
   
  this.serverService.active_status.next(chat_list);
}
   })

  }

   chatinstaPanelDetail2(chat_id){
    // this.single_chat_id = chat_id;
    // // this.single_sender_id = sender_id;
    // // console.log(this.single_sender_id)
    // console.log(this.single_chat_id)
   $('#chat_msg').val('');
   let req:any = new Object();
   let inst_req:any = new Object();
   inst_req.action = "single_chat_data";
   inst_req.chat_id = chat_id;
   req.operation="wpchat";
   req.moduleType="wpchat";
   req.access_token=localStorage.getItem('access_token');
   req.element_data = inst_req;
   this.serverService.sendServer(req).subscribe((response:any)=>{
console.log(response);
if(response.result.status==true){
  this.chatautoScroll();
 this.insta_message = response.result.data;
 this.profile_photo = response.result.data.chat_data.profile_pic;
 this.profile_name = response.result.data.chat_data.first_name;
 this.insta_msg_data = response.result.data.chat_list;
 this.chat_panel_detail_type = "chat_detail"; 
 if(response.result.data.chat_data.seen_status == '1'){
  this.seen_status = true;
}else{
  this.seen_status = false;
}
 console.log(this.profile_photo)
 console.log(this.profile_name)
}
   })

  }
   chatinstaPanelDetail1(chat_id){
    // this.single_chat_id = chat_id;
    // // this.single_sender_id = sender_id;
    // // console.log(this.single_sender_id)
    // console.log(this.single_chat_id)
   $('#chat_msg').val('');
   let req:any = new Object();
   let inst_req:any = new Object();
   inst_req.action = "single_chat_data";
   inst_req.chat_id = chat_id;
   req.operation="wpchat";
   req.moduleType="wpchat";
   req.access_token=localStorage.getItem('access_token');
   req.element_data = inst_req;
   this.serverService.sendServer(req).subscribe((response:any)=>{
console.log(response);
if(response.result.status==true){
  this.chatautoScroll();
 this.insta_message = response.result.data;
 this.profile_photo = response.result.data.chat_data.profile_pic;
 this.profile_name = response.result.data.chat_data.first_name;
 this.insta_msg_data = response.result.data.chat_list;
 this.chat_panel_detail_type = "chat_detail"; 
 if(response.result.data.chat_data.seen_status == '1'){
  this.seen_status = true;
}else{
  this.seen_status = false;
}
 console.log(this.profile_photo)
 console.log(this.profile_name)
}
   })

  }


 
  ngOnDestroy() {
    clearTimeout(this.callonce);
     
  }
   sendChatMessageData(){
  
   
    var chat_message=  this.chat_message.nativeElement.value;
    chat_message = chat_message.trim();
   if (chat_message.length > 0) {

   console.log(chat_message);
       let api_req:any = new Object();
      let chat_req:any = new Object();
      chat_req.action="insta_reply_message";
      chat_req.chat_id=this.single_chat_id;
      chat_req.user_id=this.loginUser;
      chat_req.admin_id=this.uadmin_id;
      chat_req.sender_id=this.single_sender_id;
      chat_req.chat_message=chat_message;
      api_req.operation="wpchat";
      api_req.moduleType="wpchat";
      api_req.api_type="web";
      api_req.access_token=localStorage.getItem('access_token');
      api_req.element_data = chat_req;
      
            this.serverService.sendServer(api_req).subscribe((response:any) => {
  
              if(response.result.data==1){

                 this.chatautoScroll();
                 this.chatinstaPanelDetail(this.single_chat_id,this.single_sender_id);
                 $('#chat_msg').val('');
              }
                else{
                  iziToast.warning({
                    message:'Sorry,Chat was Closed. You have to respond to your customer`s message within 24 hours.',
                    position:'topRight'
                   });
                }
            }, 
            (error)=>{
                console.log(error);
            });

   }

  }
  delete(id){
    let access_token: any=localStorage.getItem('access_token');
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        let api_req:any = '{"operation":"insta_chat", "moduleType":"insta_chat", "api_type": "web", "element_data":{"action":"chat_message_delete","chat_msg_id":"'+id+'"}}';
        this.serverService.sendServer(api_req).subscribe((response:any) => {
          if(response.result.data==true){
            iziToast.success({
              message: "message deleted successfully",
              position: 'topRight'
          });         
          this.chatinstaPanelDetail1(this.single_chat_id);
        }
        }, 
        (error)=>{
            console.log(error);
        });
      }
    })
  }
  oncemore(){
    // alert("all");
    clearTimeout(this.callonce);

      // this.chatPanelViewoncemore(this.chat_detail_key);
    }

    // chatPanelViewoncemore(chat_id){
  
  
    //   let api_req:any = new Object();
    //   let chat_req:any = new Object();
    //   chat_req.action="fb_message_panel";
      
    //   chat_req.user_id=this.loginUser;
    //   api_req.operation="wpchat";
    //   api_req.moduleType="wpchat";
    //   api_req.api_type="web";
    //   api_req.access_token=localStorage.getItem('access_token');
    //   api_req.element_data = chat_req;
      
    //         this.serverService.sendServer(api_req).subscribe((response:any) => {
    //           console.log(response);
    //           if(response.status == true){
                   
    //                this.chat_panel_list = response.result.data;
    //                this.search_list = response.result.data;
    
    //                if(chat_id == "all" || chat_id == "" || chat_id == 0){
    //                  this.chat_panel_detail_type = "chat_screen";
    //                }
    //                else{
    //                  this.chat_panel_details = response.result.data.chat_detail_list;
    //                  this.chat_panel_detail_type = "chat_detail";
    //                  this.chatPanelDetailoncemore(chat_id);
    //                }
    
                   
    //                this.chatautoScroll();
    //                this.chat_detail_key = chat_id;
    //               //  alert("callerd");
 
    //               //  this.callonce = setTimeout(() => {
    //               //    this.chatPanelViewoncemore(this.chat_detail_key);
    //               //  alert("callerd");
    //               //  }, 15000);
    //           }
              
    //         }, 
    //         (error)=>{
    //           iziToast.warning({
    //             message:'Sorry,server not respond',
    //             position:'topRight'
    //            });
    //             console.log(error);
    //         });
    
    
    //   }

      chatPanelDetailoncemore(chat_id){
        let api_req:any = new Object();
      let chat_req:any = new Object();
      chat_req.action="fb_single_chat";
      chat_req.sender_id=chat_id;
      chat_req.user_id=this.loginUser;
      api_req.operation="wpchat";
      api_req.moduleType="wpchat";
      api_req.api_type="web";
      api_req.access_token=localStorage.getItem('access_token');
      api_req.element_data = chat_req;
      
            this.serverService.sendServer(api_req).subscribe((response:any) => {
             
              if(response.status == true){
                console.log(response.status);
             //$('#chat_msg').val('Hello before we proceed kindly read through this informed consent.\nReply "Agree" if you accept the terms of the consent. \nThank you');

                   this.chat_panel_detail_type = "chat_detail";
                   this.chat_panel_details = response.result.data;
                   let last:any = this.chat_panel_details[this.chat_panel_details.length-1];
      console.log(last.id);
                   this.first_name = response.result.data[0].first_name;
                   this.last_name = response.result.data[0].last_name;
                   this.profile_pic= response.result.data[this.chat_panel_details.length-1].profile_pic;
                   this.page_name= response.result.data[0].page_name;
                  //  this.page_pic=response.result.data[0].page_picture;
                   this.page_pic=response.result.data[0].profile_pic;
      
                   this.chatautoScroll(); 
                   this.chat_detail_key = chat_id;
              }
                
              // clearTimeout(this.callonce);
       
              // $("#calloncemore").click();  
            }, 
            (error)=>{
                console.log(error);
            });
      
      
      }


  // chatPanelView(chat_id){
  
  
  // let api_req:any = new Object();
  // let chat_req:any = new Object();
  // chat_req.action="fb_message_panel";
  
  // chat_req.user_id=this.loginUser;
  // api_req.operation="wpchat";
  // api_req.moduleType="wpchat";
  // api_req.api_type="web";
  // api_req.access_token=localStorage.getItem('access_token');
  // api_req.element_data = chat_req;
  
  //       this.serverService.sendServer(api_req).subscribe((response:any) => {
  //         console.log(response);
  //         if(response.status == true){
               
  //              this.chat_panel_list = response.result.data;
  //              this.search_list = response.result.data;

  //              if(chat_id == "all" || chat_id == "" || chat_id == 0){
  //                this.chat_panel_detail_type = "chat_screen";
  //              }
  //              else{
  //                this.chat_panel_details = response.result.data.chat_detail_list;
  //                this.chat_panel_detail_type = "chat_detail";
  //                this.chatPanelDetail(chat_id);
  //              }

               
  //              this.chatautoScroll();
  //              this.chat_detail_key = chat_id;
  //         }
            
  //       }, 
  //       (error)=>{
  //           console.log(error);
  //       });


  // }


//   chatPanelList(search_text){
  
  
  
//     let api_req:any = new Object();
//   let chat_req:any = new Object();
//   chat_req.action="get_queue_chat_list";
//   chat_req.search_text=search_text;
//   chat_req.user_id=this.loginUser;
//   api_req.operation="wpchat";
//   api_req.moduleType="wpchat";
//   api_req.api_type="web";
//   api_req.access_token=localStorage.getItem('access_token');
//   api_req.element_data = chat_req;
  
//         this.serverService.sendServer(api_req).subscribe((response:any) => {
          
//           if(response.result.status==1){
//                this.chat_panel_list = response.result.data.chat_list;
       
//           }
            
//         }, 
//         (error)=>{
//             console.log(error);
//         });


// }


chatPanelList(search_text){
  console.log(search_text);


    const lcText = search_text.toString().toLowerCase(); // calculate this once
    this.chat_panel_list = this.search_list.filter(
      e => (
        // Added initial opening brace
        (e.first_name.toLowerCase().indexOf(lcText) === 0) ||
        (e.last_name.toLowerCase().indexOf(lcText) === 0)         
      )// added closing brace
    );
        console.log(this.chat_panel_list);
  
}


chatautoScroll(){   
  setTimeout(()=>{ 
    $(".card-body.chat-content").scrollTop($(".card-body.chat-content")[0].scrollHeight);
 
    }, 10);
  }

chatPanelDetail(chat_id){
$('#chat_msg').val('');
let api_req:any = new Object();
let chat_req:any = new Object();
chat_req.action="fb_single_chat";
chat_req.sender_id=chat_id;
chat_req.user_id=this.loginUser;
api_req.operation="wpchat";
api_req.moduleType="wpchat";
api_req.api_type="web";
api_req.access_token=localStorage.getItem('access_token');
api_req.element_data = chat_req;

      this.serverService.sendServer(api_req).subscribe((response:any) => {
       
        if(response.status == true){
          console.log(response.status);
             this.chat_panel_detail_type = "chat_detail"; 
          if(this.uadmin_id=='821')
           $('#chat_msg').val('Hello before we proceed kindly read through this informed consent.\nReply "Agree" if you accept the terms of the consent. \nThank you');
             this.chat_panel_details = response.result.data;
             let last:any = this.chat_panel_details[this.chat_panel_details.length-1];
console.log(last.id);
             this.first_name = response.result.data[0].first_name;
             this.last_name = response.result.data[0].last_name;
             this.profile_pic= response.result.data[this.chat_panel_details.length-1].profile_pic;
             this.page_name= response.result.data[0].page_name;
            //  this.page_pic=response.result.data[0].page_picture;
             this.page_pic=response.result.data[0].profile_pic;

            //  this.chatautoScroll(); 
             this.chat_detail_key = chat_id;
        }
          
        clearTimeout(this.callonce);
 
        $("#calloncemore").click();  
      }, 
      (error)=>{
          console.log(error);
      });


}

onMessageSend($event){
  
  if($event.keyCode == 13 && !$event.shiftKey){

// alert("sdjs");
          this.sendChatMessageData();
          $event.stopPropagation();
        return false;
        }


  }

addWhatsappMedia(){ 
  let access_token: any=localStorage.getItem('access_token');
  // let chat_id: any=this.chat_detail_id.nativeElement.value;
  var self = this;
    var formData = new FormData();
    formData.append('operation', 'chat_insta');
    formData.append('moduleType', 'chat_insta');
    formData.append('api_type', 'web');
    // formData.append('api_type', 'web');
    formData.append('action', 'insta_reply_media_upload');
    formData.append('access_token', access_token);
    formData.append('insta_media', $('#insta_media')[0].files[0]);
    formData.append('user_id', this.loginUser);
    formData.append('chat_id', this.single_chat_id);
    formData.append('sender_id',this.single_sender_id);
    console.log(formData);
   
  $.ajax({  
    url:"https://omni.mconnectapps.com/api/v1.0/index_new.php",  
    // url:"https://omni.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      this.parsed_data = JSON.parse(data);
      console.log(this.parsed_data );
      if(this.parsed_data.status == 'true'){  
        //$('#whatsapp_media_url').val(this.parsed_data.url);
        $('#createNewWidget').modal('hide');
        $('#insta_media').val('');
        self.chatinstaPanelDetail1(this.parsed_data.chat_id);
        self.chatautoScroll();
       // $('#hit_image').click();
        //$('#chat_details_call').click();
        
        //this.chatinstaPanelDetail(this.single_chat_id,this.single_sender_id);
        
        // this.chatPanelDetail(this.chat_detail_id.nativeElement.value);

      } else {
        iziToast.warning({
          message:'Sorry, you have to respond to your customer`s message within 24 hours.',
          position:'topRight'
         });
        $('#createNewWidget').modal('hide');

      }
    }  
});  

  }


  get_temps(){
    let access_token: any=localStorage.getItem('access_token');
    
    let api_req:any = '{"operation":"template", "moduleType":"template", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"listTemplateByUSer","admin_id":"'+this.uadmin_id+'","user_id":"'+this.loginUser+'"}}';
    
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
       
      this.temp_list = response.result.data;
      console.log(this.temp_list);
      } 
    }, 
    (error)=>{
      console.log(error);
    });
    }
  
    
  
  template(){
    var options = {};
          $.map(this.temp_list,
              function(o) {
                  options[o.template_message] = o.template_name;
        });
        console.log(options);
        console.log(this.temp_list);
    const fruit =   Swal.fire({
      title: 'Select Template',
      input: 'select',
      inputOptions:options,
      inputPlaceholder: 'Select a Template',
     
      confirmButtonText: 'Pick out',
      showCancelButton: true,
      }).then(function (inputValue) {
      if (inputValue) {
        console.log(inputValue.value);
        console.log(fruit);
        $('#chat_msg').val(inputValue.value);
      }
    });
      
     
  }


  // chatPanelView2(chat_id){
  
  
  //   let api_req:any = new Object();
  //   let chat_req:any = new Object();
  //   chat_req.action="fb_message_panel";
    
  //   chat_req.user_id=this.loginUser;
  //   api_req.operation="wpchat";
  //   api_req.moduleType="wpchat";
  //   api_req.api_type="web";
  //   api_req.access_token=localStorage.getItem('access_token');
  //   api_req.element_data = chat_req;
    
  //         this.serverService.sendServer(api_req).subscribe((response:any) => {
  //           console.log(response);
  //           console.log(chat_id);
  //           console.log(this.chat_detail_key);
  //           if(response.status == true){
                 
  //                this.chat_panel_list = response.result.data;
 
  //                if(chat_id == this.chat_detail_key){
              
  //                  this.chat_panel_details = response.result.data.chat_detail_list;
  //                  this.chat_panel_detail_type = "chat_detail";
  //                  this.chatPanelDetail2(chat_id);
  //                 // this.chatPanelDetail(chat_id);
  //                }
  
                 
  //               //  this.chatautoScroll();
  //               //  this.chat_detail_key = chat_id;
  //           }
              
  //         }, 
  //         (error)=>{
  //             console.log(error);
  //         });
  
  
  //   }



chatPanelDetail2(chat_id){ 

  let api_req:any = new Object();
  let chat_req:any = new Object();
  chat_req.action="fb_single_chat";
  chat_req.sender_id=chat_id;
  chat_req.user_id=this.loginUser;
  api_req.operation="wpchat";
  api_req.moduleType="wpchat";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  api_req.element_data = chat_req;
  
        this.serverService.sendServer(api_req).subscribe((response:any) => {      
          if(response.status == true){ 
            setTimeout(()=>{ 
              // alert('rolled')
			  $(".card-body.chat-content").scrollTop($(".card-body.chat-content")[0].scrollHeight);
			  }, 1500);           
            console.log(response.status);
               this.chat_panel_detail_type = "chat_detail"; 
          
               this.chat_panel_details = response.result.data;
               let last:any = this.chat_panel_details[this.chat_panel_details.length-1];
  console.log(last.id);
               this.first_name = response.result.data[0].first_name;
               this.last_name = response.result.data[0].last_name;
               this.profile_pic= response.result.data[this.chat_panel_details.length-1].profile_pic;
               this.page_name= response.result.data[0].page_name;
               this.page_pic=response.result.data[0].page_picture;
  
              //  this.chatautoScroll(); 
               this.chat_detail_key = chat_id;
          }
            
        
        }, 
        (error)=>{
            console.log(error);
        });
  
  
  }
}
