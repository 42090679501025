
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';

declare var iziToast:any;
declare var $:any;
@Component({
  selector: 'app-node-admins',
  templateUrl: './node-admins.component.html',
  styleUrls: ['./node-admins.component.css']
})
export class NodeAdminsComponent implements OnInit {

  constructor(private serverService:ServerService, private http:HttpClient ) { }
  adminlists;
  node_id;
  hardware_id;
  ngOnInit(): void {
this.getAllLicense();
   // this.getNodeUsers();
  }
  getAllLicense(){
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"list_all_license_key"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        this.adminlists= response.result.data; 
        console.log(this.adminlists);
      } 
      // else {
      //   this.recordNotFound = true;
      // }
    }, 
    (error)=>{
        console.log(error);
    }); 

  }

  addLicData(){

  
    let cus_name=$('#admin_name').val();
    let exp_date=$('#exp_date').val();
    let api_req:any = new Object;
    api_req.operation="agents";
    api_req.moduleType="agents";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data=new Object;
    
    api_req.element_data.action="add_license_key";
    api_req.element_data.customer_name=cus_name;
    api_req.element_data.exp_date=exp_date;  

    // if(agent_req.status == true){  this.dep_status = 1 } else { this.dep_status  = 0 }
        let access_token: any=localStorage.getItem('access_token');
      
        // let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_report","report_name":"'+agent_req.department_name+'","report_url":"'+agent_req.report_path+'"}}';
      
            this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.result.data == 1) {
                    $('#add_license').modal('hide');
                    iziToast.success({
                        message: "Node License added successfully",
                        position: 'topRight'
                    });
                    this.getAllLicense();

                }
                else if (response.result.data == 0) {
                  iziToast.warning({
                      message: "License not inserted",
                      position: 'topRight'
                  });
              }
            else{
                
                    iziToast.error({
                        message: "Please try again",
                        position: 'topRight'
                    });
                
            }
      
        },
        (error) => {
             iziToast.error({
                message: "Sorry, some server issue occur. Please contact admin",
                position: 'topRight'
            });
            console.log(error);
        });
      
      
      
      }
 editLicdata(id){
        let access_token: any=localStorage.getItem('access_token');
        let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"editnode_license_key","id":"'+id+'"}}';
        this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.status==true){
  $('#editNode_license').modal('show');
  let user_data= response.result.data[0];
  $('#Upd_admin_name').val(user_data.customer_name);
  $('#Upd_exp_date').val(user_data.expiry_date);
this.node_id=user_data.id;

}
},(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
            
          });
      });
 }
 updateNodeLicense(id){
  let cus_name= $('#Upd_admin_name').val();
  let exp_date= $('#Upd_exp_date').val();
  let access_token: any=localStorage.getItem('access_token'); 

  let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"updatenode_license_key","id":"'+id+'","customer_name":"'+cus_name+'","exp_date":"'+exp_date+'","hardware_id":"'+this.hardware_id+'"}}';
this.serverService.sendServer(api_req).subscribe((response:any) => {
if(response.result.data==1){
  this.getAllLicense();
  // var body = "action=" + 'chkHardwareId_expiry_date_update'+ "&qshwidValue=" + this.hardware_id + "&expiry_date=" + exp_date;
  // this.http.post("https://erp.cal4care.com/mconnect/checkhardwareId.php?action=chkHardwareId_expiry_date_update&qshwidValue=D02F-F5469-7D06-58A46-4B91-6AF48-B1A8-DA9D&expiry_date=2022-10-30", body).subscribe((data) => {});
  // console.log(id)
  iziToast.success({
    message:"License updated succesfully",
    position:"topRight"
  });
  $('#editNode_license').modal('hide');

}else{  
  iziToast.warning({
    message:"some error occured.please try again",
    position:"topRight"
  });
}        },(error)=>{
          console.log(error);
          iziToast.error({
            message:"Server issue occured.",
            position:"topRight"
          });
      });
 }

 
  actCamp(id){
        var stat = '0'; 
         if($('#status_node_'+id).prop('checked')){ stat = '1';}
        let access_token: any=localStorage.getItem('access_token');
        
                let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"toggle_status_license_key","id":"'+id+'","status":"'+stat+'"}}';
                this.serverService.sendServer(api_req).subscribe((response:any) => {
                  if(response.result.data == 1){
                      this.getAllLicense();
                      iziToast.success({
                        message:"License Status changed",
                        position:"topRight"
                      });  
       }else{
         iziToast.error({
           message:"Nothing has been updated.Please try again",
           position:"topRight"
         });
       }

        }, 
        (error)=>{
            console.log(error);
        });
      }  
      

      deleteLicense(id){
                let access_token: any=localStorage.getItem('access_token');
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
              let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"delenode_license_key","id":"'+id+'"}}';
              this.serverService.sendServer(api_req).subscribe((response:any) => {
                if(response.status==true){
                 this.getAllLicense();
                 iziToast.success({
                  message:"Customer data deleted successfully",
                  position:"topRight"
                });
                } 
        
              }, 
              (error)=>{
                  console.log(error);
              });
            }
          })
               
       }

//   getNodeUsers(){
//     let access_token: any=localStorage.getItem('access_token');
//     let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"node_app_userslist","admin_id":"1"}}';
//     this.serverService.sendServer(api_req).subscribe((response:any) => {
//       if(response.status==true){
//         this.adminlists= response.result.data; 
//         console.log(this.adminlists);
//       } 
//       // else {
//       //   this.recordNotFound = true;
//       // }
//     }, 
//     (error)=>{
//         console.log(error);
//     }); 

//   }
//   addDeptData(){

//     let domain_url=$('#url_path').val();
//     let cus_name=$('#admin_name').val();
//     let domain=$('#domain_name').val();
//     let api_req:any = new Object;
//     api_req.operation="agents";
//     api_req.moduleType="agents";
//     api_req.api_type="web";
//     api_req.access_token=localStorage.getItem('access_token');
//     api_req.element_data=new Object;
//     api_req.element_data.admin_id='1';
//     api_req.element_data.action="addnode_app_users";
//     api_req.element_data.customer_name=cus_name;
//     api_req.element_data.domain=domain;
//     api_req.element_data.domain_url=domain_url;
//     api_req.element_data.status="OFF";

//     // if(agent_req.status == true){  this.dep_status = 1 } else { this.dep_status  = 0 }
//         let access_token: any=localStorage.getItem('access_token');
      
//         // let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_report","report_name":"'+agent_req.department_name+'","report_url":"'+agent_req.report_path+'"}}';
      
//             this.serverService.sendServer(api_req).subscribe((response: any) => {
//             if (response.result.data == 1) {
//                     $('#add_user').modal('hide');
//                     iziToast.success({
//                         message: "Node user added successfully",
//                         position: 'topRight'
//                     });
//                     this.getNodeUsers();

//                 }
//                 else if (response.result.data == 2) {
//                   iziToast.warning({
//                       message: "Wrap Up Code name already inserted",
//                       position: 'topRight'
//                   });
//               }
//             else{
                
//                     iziToast.error({
//                         message: "Wrap Up Code not added. Please try again",
//                         position: 'topRight'
//                     });
                
//             }
      
//         },
//         (error) => {
//              iziToast.error({
//                 message: "Sorry, some server issue occur. Please contact admin",
//                 position: 'topRight'
//             });
//             console.log(error);
//         });
      
      
      
//       }
//   actCamp(url,id){
//         // let stat =''
//         var action='api_access_off';
//         var stat = 'OFF'; 
//          if($('#status_node_'+id).prop('checked')){ stat = 'ON';action='api_access_on';}

//         // if($('#status_node').prop('checked')){ stat = 'ON'; action='api_access_on';alert('ON')} else {stat = 'OFF';action='api_access_off'; }
       
//         let access_token: any=localStorage.getItem('access_token');
//         let api_req2:any = '{"operation":"apiaccess", "moduleType": "apiaccess", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"'+action+'"}}';
//         this.serverService.sendServer3(api_req2,url).subscribe((response:any) => {
//           if(response.result.message == 'api access on'){
//             this.callupdateFunc(stat,id,'ON');
//          }else  if(response.result.message == 'api access off'){
//           this.callupdateFunc(stat,id,'OFF');
//        }else{
//          iziToast.error({
//            message:"Nothing has been updated.Please try again",
//            position:"topRight"
//          });
//        }

//         }, 
//         (error)=>{
//             console.log(error);
//         });
//       }      
//       callupdateFunc(stat,id,mes){
      
//         let access_token: any=localStorage.getItem('access_token');
//         let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"updatenode_app_status","admin_id":"1","id":"'+id+'","status":"'+stat+'"}}';
//         this.serverService.sendServer(api_req).subscribe((response:any) => {
//           if(response.result.data == 1){
//             this.getNodeUsers();
//             iziToast.success({
//               message:"API Access have been "+mes+" succesfully",
//               position:"topRight"
//             });
//          }else{
//           iziToast.error({
//             message:"Some issue occure in omni",
//             position:"topRight"
//           });
//          }
//         }, 
//         (error)=>{
//             console.log(error);
//             iziToast.error({
//               message:"Server issue occured.",
//               position:"topRight"
//             });
//         });
//       }
//  editnodedata(id){
//         let access_token: any=localStorage.getItem('access_token');
//         let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"editnode_app_users","admin_id":"1","id":"'+id+'"}}';
//         this.serverService.sendServer(api_req).subscribe((response:any) => {
// if(response.status==true){
//   $('#editNode_user').modal('show');
//   let user_data= response.result.data[0];
//   $('#Upd_admin_name').val(user_data.customer_name);
//   $('#Upd_domain_name').val(user_data.domain);
//   $('#upd_url_path').val(user_data.domain_url);
// this.node_id=user_data.id;

// }
//         },(error)=>{
//           console.log(error);
//           iziToast.error({
//             message:"Server issue occured.",
//             position:"topRight"
//           });
//       });

//   }
// updareNodeuser(id){
//   let cus_name= $('#Upd_admin_name').val();
//   let dom_name= $('#Upd_domain_name').val();
//   let dom_url= $('#upd_url_path').val();
//   let access_token: any=localStorage.getItem('access_token');

//   let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"updatenode_app_users","admin_id":"1","id":"'+id+'","customer_name":"'+cus_name+'","domain":"'+dom_name+'","domain_url":"'+dom_url+'"}}';
// this.serverService.sendServer(api_req).subscribe((response:any) => {
// if(response.result.data==1){
//   this.getNodeUsers();
//   iziToast.success({
//     message:"Customer data updated succesfully",
//     position:"topRight"
//   });
//   $('#editNode_user').modal('hide');

// }else{
//   iziToast.warning({
//     message:"some error occured.please try again",
//     position:"topRight"
//   });
// }
//         },(error)=>{
//           console.log(error);
//           iziToast.error({
//             message:"Server issue occured.",
//             position:"topRight"
//           });
//       });

//  }
//  deletedata(id){
//         let access_token: any=localStorage.getItem('access_token');
//   Swal.fire({
//     title: 'Are you sure?',
//     text: "You won't be able to revert this!",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'Yes, delete it!'
//   }).then((result) => {
//     if (result.value) {
//       let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"delenode_app_users","admin_id":"1","id":"'+id+'"}}';
//       this.serverService.sendServer(api_req).subscribe((response:any) => {
//         if(response.status==true){
//          this.getNodeUsers();
//          iziToast.success({
//           message:"Customer data deleted successfully",
//           position:"topRight"
//         });
//         } 

//       }, 
//       (error)=>{
//           console.log(error);
//       });
//     }
//   })
       
//       }
}
