import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast;
@Component({
  selector: 'app-mrvoip-api',
  templateUrl: './mrvoip-api.component.html',
  styleUrls: ['./mrvoip-api.component.css']
})
export class MrvoipApiComponent implements OnInit {
  edit_kb_apis : FormGroup;
  public addresses: any[] = [{
    id: 1,
    api_name: '',
    api_value: '',
    paid : '',
    api_link : '',
  }];
  constructor(public serverservices : ServerService,private fb: FormBuilder) {
    
   }
  
  apis;
  dis_price = false;
  disable_price = false;
  paid_edit_val;
  mr_id;
  api_price_val;
  ngOnInit(): void {
    this.edit_kb_apis = new FormGroup({
      'api_name' : new FormControl(null),
      'api_value':new FormControl(null),
      'kb_url':new FormControl(null),
      'api_price':new FormControl(null)
    });
    this.get_all_apis();
  }
  addapi(){
    this.addresses = [{
      id: 1,
      api_name: '',
      api_value: '',
      paid : false,
      api_link : '',
      api_price : '',
    }];
    $('#add_qusnform').modal('show');
   
  }
  addAddres(){
    this.addresses.push({
      id: this.addresses.length + 1,
      api_name: '',
      api_value: '',
      paid : '',
      api_link : '',
      api_price : '',
    });
  }
  add_apis(){
    console.log(this.addresses)
    var api_name = [];
    var api_value = [];
    var pain_val = [];
    var api_links = [];
    var api_price = [];
    this.addresses.forEach(element=>{
      console.log(element)
      api_name.push(element.api_name);
      api_value.push(element.api_value);
      pain_val.push(element.paid);
      api_links.push(element.api_link);
      api_price.push(element.api_price);
      console.log(api_name);
    })
    var name = api_name.join(',');
    var api_val=api_value.join(',');
    var paid_value = pain_val.join(',');
    var api_link_val = api_links.join(',');
    var api_price_val = api_price.join(',');
    console.log(name);
    console.log(api_val);
    console.log(paid_value);
    if(name==''||api_val==''||paid_value==''){
      return false;
    }
  
    let api_req: any = '{"operation":"power_bi", "moduleType":"power_bi", "api_type": "web", "element_data":{"action":"insert_api_values","api_name":"'+name+'","api_value":"'+api_val+'","paid_value":"'+paid_value+'","api_link":"'+api_link_val+'","api_price_val":"'+api_price_val+'"}}';
    console.log(api_req)
    this.serverservices.sendServer(api_req).subscribe((response:any)=>{
      if(response.result.data==true){
        $('#add_qusnform').modal('hide');
        this.get_all_apis();
        iziToast.success({
          message: "MrVoIP API update Successfully",
          position: 'topRight'
      });
      }else{
        iziToast.error({
          message: "MrVoIP API already added",
          position: 'topRight'
      });
      }
    })
    }

    change_paid(api_val){
      let api_req:any = '{"operation":"power_bi", "moduleType":"power_bi", "api_type": "web", "element_data":{"action":"change_paid_status","api_value":"'+api_val+'"}}';
      this.serverservices.sendServer(api_req).subscribe((response:any)=>{
        if(response.result.data==true){
          this.get_all_apis();
          console.log(response);
        }
      })
    }

    get_all_apis(){
      let api_req: any = '{"operation":"power_bi", "moduleType":"power_bi", "api_type": "web", "element_data":{"action":"get_paid_apis"}}';
      this.serverservices.sendServer(api_req).subscribe((response:any)=>{
        if(response.result.status==true){
          this.apis = response.result.data;
        }
      })
    }
    edit_api(val,id){
      this.mr_id = id;
      $("#edit_apis_data").modal("show");

let api_req:any = '{"operation":"power_bi", "moduleType":"power_bi", "api_type": "web", "element_data":{"action":"edit_apis","api_value":"'+val+'"}}';
this.serverservices.sendServer(api_req).subscribe((response:any)=>{
  if(response.result.status==true){
    this.paid_edit_val = response.result.data[0].paid_value;
    if(this.paid_edit_val==2){
      this.disable_price=true;
    }else{
      this.disable_price=false;
    }
    this.api_price_val = response.result.data[0].api_price;
    this.edit_kb_apis.setValue({
      'api_name' : response.result.data[0].api_name,
      'api_value' : response.result.data[0].api_value,
      'kb_url' : response.result.data[0].api_link,
      'api_price' : response.result.data[0].api_price
    })
  }
})
    }
    delete_api(val,id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
      let api_req:any = '{"operation":"power_bi", "moduleType":"power_bi", "api_type": "web", "element_data":{"action":"delete_api","api_value":"'+val+'","api_id":"'+id+'"}}';
      this.serverservices.sendServer(api_req).subscribe((response:any)=>{
        if(response.result.status==true){
          this.get_all_apis();
          iziToast.success({
            message: "MrVoIP API Deleted Successfully",
            position: 'topRight'
        });
        }else{
          iziToast.error({
            message: "Sorry, Some Error Occured",
            position: 'topRight'
        });
        }
      })
    }
  })
    }
    check(i:number,isChecked:boolean){
      console.log(isChecked);
      if(isChecked==true){
       this.addresses['price'].at(i-1).disable();
      }else{
        this.addresses['price'].at(i-1).enable();
        // $("#price").at(i-1).enable();
      }
    }
    checked_data(evn:any){
      console.log(evn)
      if(evn==2){
        this.disable_price=false;
        this.paid_edit_val = 1;
        this.edit_kb_apis.patchValue({
          'api_price' : this.api_price_val
        })
      }else{
        this.disable_price=true;
        this.paid_edit_val = 2;
        this.edit_kb_apis.patchValue({
          'api_price' : 0
        })
      }
    }
    edit_apis(){
let api_req:any = '{"operation":"mrvoip_apis", "moduleType":"mrvoip_apis", "api_type": "web", "element_data":{"action":"edit_apis_omni","api_name":"'+this.edit_kb_apis.value.api_name+'","kb_url":"'+this.edit_kb_apis.value.kb_url+'","api_price":"'+this.edit_kb_apis.value.api_price+'","paid_val":"'+this.paid_edit_val+'","api_value":"'+this.edit_kb_apis.value.api_value+'","api_id":"'+this.mr_id+'"}}';
console.log(api_req);
this.serverservices.sendServer(api_req).subscribe((res:any)=>{
  if(res.result.status==true){
    if(res.result.data==1){
      $("#edit_apis_data").modal("hide");
      this.get_all_apis();
      iziToast.success({
        message: "MrVoIP API Updated Successfully",
        position: 'topRight'
    });
    }else{
      iziToast.error({
        message: "Sorry, Some Error Occured",
        position: 'topRight'
    });
    }
  }
}) 
    }
  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }
}
