import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerService } from '../services/server.service';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'app-videos-blog',
  templateUrl: './videos-blog.component.html',
  styleUrls: ['./videos-blog.component.css']
})
export class VideosBlogComponent implements OnInit {
  url = `https://erp.cal4care.com/cms/cms_data_redirect_voip.php?action=get_omni_mconnectapps&domain_name=omni_mconnectapps.com`;
  items = [];
  video_link;
  vid_name;
  doc_link;
  doc_name;
  h_voice3cx=false;
h_wp_unoff=false;
h_wp_off =false;
wall_1=false;
wall_2=false;
wall_3=false;
wall_4=false;
h_contact=false;
h_dialer=false;
h_lead=false;
h_sms=false;
h_pred=false;
h_webinar=false;
h_int_chat=false;
h_chat=false;
  constructor(private http: HttpClient,private serverService: ServerService) {
    
    this.http.get(this.url).toPromise().then(data => {
      console.log(data[0].data);
      
      for (let key in data)
        if (data.hasOwnProperty(key))
          this.items.push(data[key]);
    });
   }

  ngOnInit(): void {
    // $('#video_play').modal('hide');
     this.hasContactAccess();
  } 
 
  showvid(link,name){  
    var url= link.split('/');
    //alert(url[3]); 
    this.video_link="https://www.youtube.com/embed/"+url[3];
    this.vid_name=name;
    //alert(this.video_link);
    // $("#video_play").model('show');
    $("#video_play").modal('show');
   // $('#video_play').modal('hide');
  }
  showdoc(link){   
     this.doc_link=link;
    $("#document_model").modal('show');   
  }
  hasContactAccess(){
    let api_req:any = new Object();
    let conct_req:any = new Object();
    conct_req.action="has_contact_access";
    conct_req.user_id=localStorage.getItem('userId');
    api_req.operation="contact";
    api_req.moduleType="contact";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = conct_req;
    // console.log(api_req);
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){  
        if(response.result.data.voice_3cx == 1){
          this.h_voice3cx= true;
      }
      if(response.result.data.has_whatsapp == 1){               
        if(response.result.data.whatsapp_type == 0){
          this.h_wp_unoff = true;
          this.h_wp_off = false;
        }
        else {
          this.h_wp_unoff = false;
          this.h_wp_off = true;
        }
      }
      if(response.result.data.wallboard_one == 1){
        this.wall_1 = true;
      }
      if(response.result.data.wallboard_two == 1){
        this.wall_2 = true;
      }
      if(response.result.data.wallboard_three == 1){
        this.wall_3 = true;
      }
      if(response.result.data.wallboard_four == 1){
        this.wall_4 = true;
      }
      
      if(response.result.data.ext_int_status == 2){
        this.h_dialer = true;
      }
      if(response.result.data.has_contact == 1){
        this.h_contact= true;
    }
    if(response.result.data.lead == 1){
      this.h_lead = true;
    }
    if(response.result.data.has_sms == 1){
      this.h_sms = true;
    }
    if(response.result.data.has_internal_chat == 1){
      this.h_int_chat = true;
    }
    if(response.result.data.predective_dialer == 1){
      this.h_pred = true;
    }
    if(response.result.data.has_webinar == 1){
      this.h_webinar = true;
    }
    if(response.result.data.has_chat == 1){
      this.h_chat = true;
    }
      } 
    }, 
    (error)=>{
        console.log(error);
    });

  } 
  stop(){
    var el_src = $('.myvideo').attr("src");
          $('.myvideo').attr("src",el_src);
    }
}
